import React from "react";

export const LandingPage = () => {
    return <div className="site-wrapper overflow-hidden position-relative">
        <header
            className="site-header site-header--menu-center dark-mode-texts landing-17-menu  site-header--absolute site-header--sticky">
            <div className="container">
                <nav className="navbar site-navbar">
                    {/*Brand Logo*/}
                    <div className="brand-logo">
                        <a href="/#">
                            {/*light version logo (logo must be black)*/}
                            <img src="landing_assets/image/logo/logo-black.png" alt=""
                                 className="header-logo light-version-logo"/>
                            {/*Dark version logo (logo must be White)*/}
                            <img src="landing_assets/image/logo/logo-white.png" alt=""
                                 className="header-logo dark-version-logo"/>
                        </a>
                    </div>
                    <div className="menu-block-wrapper">
                        <div className="menu-overlay"></div>
                        <nav className="menu-block" id="append-menu-header">
                            <div className="mobile-menu-head">
                                <div className="go-back">
                                    <i className="fa fa-angle-left"></i>
                                </div>
                                <div className="current-menu-title"></div>
                                <div className="mobile-menu-close">&times;</div>
                            </div>
                            <ul className="site-menu-main">
                                <li className="nav-item">
                                    <a href="/#" className="nav-link-item">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://uxtheme.net/product-support/" className="nav-link-item">About</a>
                                </li>
                                <li className="nav-item nav-item-has-children">
                                    <a className="nav-link-item drop-trigger">Examples<i
                                        className="fas fa-angle-down"></i>
                                    </a>
                                    <ul className="sub-menu" id="submenu-9">
                                        <li className="sub-menu--item">
                                            <a href="/#">Dropdown 01</a>
                                        </li>
                                        <li className="sub-menu--item">
                                            <a href="/#">Dropdown 02</a>
                                        </li>
                                        <li className="sub-menu--item">
                                            <a href="/#">Dropdown 03</a>
                                        </li>
                                        <li className="sub-menu--item">
                                            <a href="/#">Dropdown 04</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-btns  header-btn-l-17 ms-auto d-none d-xs-inline-flex align-items-center">
                        {/*<a className="btn sign-in-btn focus-reset" >*/}
                        {/*    Sign in*/}
                        {/*</a>*/}
                        <a className="start-trail-btn btn focus-reset">
                            Sign in
                        </a>
                    </div>
                    {/*mobile menu trigger */}
                    <div className="mobile-menu-trigger">
                        <span></span>
                    </div>
                    {/*/.Mobile Menu Hamburger Ends*/}
                </nav>
            </div>
        </header>
        {/*navbar- */}
        {/*Hero Area */}
        <div className="hero-area-l-17 position-relative">
            <div className="container">
                <div className="row position-relative justify-content-center">
                    <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-11 order-lg-1 order-1" data-aos="fade-right"
                         data-aos-duration="800" data-aos-once="true">
                        <div className="content text-center">
                            <h1>MARKETWAVE</h1>
                            <p>Driving business growth through actionable insights</p>
                            <a href="/#" className="btn">Get Report</a>
                            {/*<span>No credit card required</span>*/}
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-9 order-lg-1 order-0">
                        <div className="hero-area-image">
                            <img src="https://placehold.co/600x400" alt="" className="w-100"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Feature Area */}
        <div className="feature-area-l-17 position-relative">
            <div className="container">
                <div className="row feature-area-l-17-items justify-content-center text-center">
                    <div className="col-lg-4 col-md-6 col-sm-9">
                        <div className="single-features single-border position-relative">
                            <div className="circle-dot-1">
                                <i className="fas fa-circle"></i>
                            </div>
                            <h4>What we do</h4>
                            <p>
                                - Empowering local businesses with comprehensive market analytics. Make informed
                                decisions,
                                identify growth opportunities, and optimize strategies with our user-friendly SaaS
                                solution.
                                <br/>
                                - Drive success in today's competitive landscape through accurate data, trends, and
                                performance indicators.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-9">
                        <div className="single-features single-border position-relative">
                            <div className="circle-dot-2">
                                <i className="fas fa-circle"></i>
                            </div>
                            <h4>How it works</h4>
                            <p>
                                - Order a market report, providing keywords and location. <br/>
                                - Our system gathers and processes information tailored to your request. <br/>
                                - Receive a comprehensive report with demographic data, similar businesses, top-rated
                                businesses, and actionable items for business enhancements.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-9">
                        <div className="single-features">
                            <div className="circle-dot-3">
                                <i className="fas fa-circle"></i>
                            </div>
                            <h4>Gain market insights</h4>
                            <p>
                                - Understand market dynamics <br/>
                                - Make informed decisions <br/>
                                - Anticipate trends <br/>
                                - Identify opportunities <br/>
                                - Respond to customer demands <br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Content Area 1*/}
        <div className="content-area-l-17-1">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-10 col-lg-12">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-8" data-aos="fade-right" data-aos-duration="800"
                                 data-aos-once="true">
                                <div className="content-img position-relative">
                                    <div className="image-1">
                                        <img src="https://placehold.co/270x360" alt=""/>
                                    </div>
                                    <div className="image-2">
                                        <img src="https://placehold.co/270x360" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-xxl-1 col-xxl-5 col-xl-6 col-lg-6 col-md-8" data-aos="fade-left"
                                 data-aos-duration="800" data-aos-once="true">
                                <div className="content section-heading-11">
                                    <h2>The Power of Data-Driven Analysis</h2>
                                    <p>Marketwave's data-driven approach is the cornerstone of our comprehensive
                                        analysis.
                                        With access to vast amounts of publicly available data, our solution harnesses
                                        the
                                        power of artificial intelligence, advanced algorithms, and machine learning
                                        techniques. We extract meaningful insights, identify emerging trends, and
                                        uncover
                                        valuable patterns within the data. This AI-powered processing not only enables
                                        faster and more accurate analysis but also ensures that the resulting reports
                                        are
                                        concise, summarized, and easily digestible for businesses seeking actionable
                                        intelligence. With Marketwave's data-driven analysis, you can confidently make
                                        informed decisions and stay ahead of the curve in today's dynamic market
                                        landscape.</p>
                                    <a href="/#"className="btn focus-reset">Get Report</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Content Area 2*/}
        <div className="content-area-l-17-2">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-10 col-lg-12">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 order-lg-1 order-1"
                                 data-aos="fade-left"
                                 data-aos-duration="800" data-aos-once="true">
                                <div className="content section-heading-11">
                                    <h2>The Demand for Informed Decision Making</h2>
                                    <p>Businesses recognize the paramount importance of gaining market insights for
                                        continuous improvement and operational efficiency. With a clear understanding of
                                        market dynamics, you can make well-informed decisions that lead to success. By
                                        leveraging market insights, organizations can anticipate trends, identify
                                        opportunities, and respond swiftly to evolving customer demands. The idea of
                                        obtaining reports for informed decision-making is not novel. Just like how you
                                        would
                                        obtain a comprehensive vehicle history report before buying a car or a termite
                                        inspection report before purchasing a house, Marketwave's reports provide
                                        transparency and assist consumers in making informed decisions. Whether it's
                                        assessing property history or conducting background checks, reports are aimed at
                                        providing transparency and valuable information.</p>
                                    <a href="/#"className="btn focus-reset">Get Report</a>
                                </div>
                            </div>
                            <div className="offset-xxl-1 col-xxl-6 col-xl-6 col-lg-6 col-md-8 order-lg-1 order-0"
                                 data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                                <div className="content-img position-relative">
                                    <div className="image-1">
                                        <img src="https://placehold.co/360x400" alt=""/>
                                    </div>
                                    <div className="image-2">
                                        <img src="https://placehold.co/320x320" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Video Area*/}
        <div className="video-area-l-17">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-12">
                        <div className="video-content text-center">
                            {/*<a href="/#"data-fancybox="" href="https://www.youtube.com/embed/9yc1lfFZX-I"><i*/}
                            {/*    className="fas fa-play font-size-7"></i></a>*/}
                            <h2>Revenue Generation Opportunities</h2>
                            <p>In addition to the benefits of utilizing our resources for market insights, Marketwave
                                offers
                                advantageous avenues for revenue generation. By leveraging our platform, you have the
                                potential to reach a wider audience through strategic advertisements, capturing the
                                attention of potential customers and driving engagement. Simultaneously, our affiliate
                                programs create mutually beneficial partnerships with relevant businesses, providing an
                                additional source of income. These initiatives not only enhance your brand visibility
                                but
                                also offer a lucrative means to diversify revenue streams, ensuring sustained financial
                                growth and success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Pricing Area*/}
        {/*    <div className="pricing-area-l-17 position-relative overflow-hidden">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row justify-content-center">*/}
        {/*                <div className="col-12 col-lg-8 text-center" data-aos="fade-down" data-aos-duration="800"*/}
        {/*                     data-aos-once="true">*/}
        {/*                    <div className="content section-heading-11">*/}
        {/*                        <h2>Choose a plan to start</h2>*/}
        {/*                        <p>Wireframes are generally created by business analysts, user experience designers, developers,*/}
        {/*                            visual*/}
        {/*                            designers, and by those with expertise</p>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="row justify-content-center" id="table-price-value" data-pricing-dynamic*/}
        {/*                 data-value-active="monthly">*/}
        {/*                <div className="col-md-12 pricing-main-area-l-17 text-center">*/}
        {/*                    <div className="toggle-btn d-inline-block  justify-content-center">*/}
        {/*                        <a href="/#"className="btn-toggle btn-toggle-2 d-flex price-deck-trigger" data-pricing-trigger*/}
        {/*                           data-target="#table-price-value" href="javascript:">*/}
        {/*                            <span className="round"></span>*/}
        {/*                        </a>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">*/}
        {/*                    <div className="single-price" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">*/}
        {/*                        <div className="price-top justify-content-between">*/}
        {/*                            <span>Basic</span>*/}
        {/*                        </div>*/}
        {/*                        <div className="main-price">*/}
        {/*                            <div className="price d-flex position-relative ">*/}
        {/*                                <span className="d-inline-block dynamic-value">$</span>*/}
        {/*                                <h2 className="d-inline-block dynamic-value" data-active="15" data-monthly="19"*/}
        {/*                                    data-yearly="199"><span*/}
        {/*                                        className="dynamic-value" data-active="/ month" data-monthly="/ month"*/}
        {/*                                        data-yearly="/year"></span></h2>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                        <p>Good for small business launching their products less than once a year</p>*/}
        {/*                        <div className="price-body pt-8">*/}
        {/*                            <ul className="pricing-list list-unstyled">*/}
        {/*                                <li><i className="icon icon-check-2-2"></i> Unlimited Blocks</li>*/}
        {/*                                <li><i className="icon icon-check-2-2"></i> 5GB Clould Storages</li>*/}
        {/*                                <li>*/}
        {/*                                    <del><i className="icon icon-check-2-2"></i> Custom Domain Names</del>*/}
        {/*                                </li>*/}
        {/*                                <li>*/}
        {/*                                    <del><i className="icon icon-check-2-2"></i>Unlimited Emails</del>*/}
        {/*                                </li>*/}
        {/*                            </ul>*/}
        {/*                        </div>*/}
        {/*                        <div className="price-btn">*/}
        {/*                            <a href="/#"className="btn" >Get Report</a>*/}
        {/*                            <p>No credit card required</p>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">*/}
        {/*                    <div className="single-price popular-pricing popular-pricing-3 position-relative" data-aos="fade-up"*/}
        {/*                         data-aos-duration="800" data-aos-once="true">*/}
        {/*                        <div className="price-top justify-content-between">*/}
        {/*                            <span>Pro</span>*/}
        {/*                        </div>*/}
        {/*                        <div className="main-price">*/}
        {/*                            <div className="price d-flex position-relative">*/}
        {/*                                <span className="d-inline-block dynamic-value ">$</span>*/}
        {/*                                <h2 className="d-inline-block dynamic-value" data-active="15" data-monthly="29"*/}
        {/*                                    data-yearly="299"> <span*/}
        {/*                                        className="dynamic-value" data-active="/  month" data-monthly="/ month"*/}
        {/*                                        data-yearly="/year"></span></h2>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                        <p>Good for small business launching their products less than once a year</p>*/}
        {/*                        <div className="price-body">*/}
        {/*                            <ul className="pricing-list list-unstyled">*/}
        {/*                                <li><i className="icon icon-check-2-2"></i> Unlimited Blocks</li>*/}
        {/*                                <li><i className="icon icon-check-2-2"></i> 5GB Clould Storages</li>*/}
        {/*                                <li><i className="icon icon-check-2-2"></i> Custom Domain Names</li>*/}
        {/*                                <li><i className="icon icon-check-2-2"></i>Unlimited Emails</li>*/}
        {/*                            </ul>*/}
        {/*                        </div>*/}
        {/*                        <div className="price-btn">*/}
        {/*                            <a href="/#"className="btn" >Get Report</a>*/}
        {/*                            <p>No credit card required</p>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*Brand Area*/}
        {/*    <div className="brand-area-l-17">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row justify-content-center">*/}
        {/*                <div className="col-xl-9 col-lg-11 col-md-12">*/}
        {/*                    <div className="content text-center">*/}
        {/*                        <p>We are proud to have some big brands as our customer</p>*/}
        {/*                    </div>*/}
        {/*                    <div className="brand-area-l-17-items d-flex justify-content-center justify-content-xl-between align-items-center flex-wrap ">*/}
        {/*                        <div className="single-brand " data-aos="fade-right" data-aos-duration="500" data-aos-once="true">*/}
        {/*                            <img src="landing_assets/image/l8/brand-logo-1.svg" alt="" />*/}
        {/*                        </div>*/}
        {/*                        <div className="single-brand " data-aos="fade-right" data-aos-duration="700" data-aos-once="true">*/}
        {/*                            <img src="landing_assets/image/l8/brand-logo-2.svg" alt="" />*/}
        {/*                        </div>*/}
        {/*                        <div className="single-brand " data-aos="fade-right" data-aos-duration="900" data-aos-once="true">*/}
        {/*                            <img src="landing_assets/image/l8/brand-logo-3.svg" alt="" />*/}
        {/*                        </div>*/}
        {/*                        <div className="single-brand " data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">*/}
        {/*                            <img src="landing_assets/image/l8/brand-logo-4.svg" alt="" />*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*Testimonial Area*/}
        {/*    <div className="testimonial-area-l-17">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row justify-content-center no-gutters border-collapse-1">*/}
        {/*                <div className="col-lg-4 col-md-6 col-sm-9 p-0">*/}
        {/*                    <div className="testimonial-card border h-100">*/}
        {/*                        <img src="landing_assets/image/l8/quote.png" alt=""/>*/}
        {/*                        <p>*/}
        {/*                            “You made it so simple. My new site is so much faster and*/}
        {/*                            easier to work with than my old site. I just choose the page, make the change and click*/}
        {/*                            save. ”*/}
        {/*                        </p>*/}
        {/*                        <div className="d-flex align-items-center">*/}
        {/*                            <div className="customer-img mr-4">*/}
        {/*                                <img src="landing_assets/image/l8/client-img-1.png" alt=""/>*/}
        {/*                            </div>*/}
        {/*                            <div className="user-identity">*/}
        {/*                                <h5>Sallie Lawson</h5>*/}
        {/*                                <span>Founder of Crips</span>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="col-lg-4 col-md-6 col-sm-9 p-0">*/}
        {/*                    <div className="testimonial-card border h-100">*/}
        {/*                        <img src="landing_assets/image/l8/quote.png" alt="" className="mb-12" />*/}
        {/*                        <p>*/}
        {/*                            “Simply the best. Better than all the rest. I’d recommend this product to beginners and*/}
        {/*                            advanced users.”*/}
        {/*                        </p>*/}
        {/*                        <div className="d-flex align-items-center">*/}
        {/*                            <div className="customer-img">*/}
        {/*                                <img src="landing_assets/image/l8/client-img-2.png" alt="" />*/}
        {/*                            </div>*/}
        {/*                            <div className="user-identity">*/}
        {/*                                <h5>Sallie Lawson</h5>*/}
        {/*                                <span>Founder of Crips</span>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="col-lg-4 col-md-6 col-sm-9 p-0">*/}
        {/*                    <div className="testimonial-card border h-100">*/}
        {/*                        <img src="landing_assets/image/l8/quote.png" alt="" className="mb-12" />*/}
        {/*                        <p>*/}
        {/*                            “This is a top quality product. No need to think twice before purchasing, you simply could*/}
        {/*                            not go wrong”*/}
        {/*                        </p>*/}
        {/*                        <div className="d-flex align-items-center">*/}
        {/*                            <div className="customer-img mr-4">*/}
        {/*                                <img src="landing_assets/image/l8/client-img-3.png" alt="" />*/}
        {/*                            </div>*/}
        {/*                            <div className="user-identity">*/}
        {/*                                <h5>Sallie Lawson</h5>*/}
        {/*                                <span>Founder of Crips</span>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*CTA Area*/}
        <div className="cta-area-l-17">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div
                            className="d-md-flex justify-content-between text-align-lg-start text-center align-items-center">
                            <h2>Manage your team in one place</h2>
                            <a href="/#" className="btn">Get Report</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*Footer Area*/}
        <footer className="footer-area-l-17 position-relative">
            <div className="footer-shape">
                <img src="landing_assets/image/l8/footer-shape.svg" alt=""/>
            </div>
            <div className="container pt-lg-23 pt-15 pb-12">
                <div className="row footer-area-l-17-items justify-content-between" data-aos="fade-left"
                     data-aos-duration="800"
                     data-aos-once="true">
                    <div className="col">
                        <div className="footer-widget widget2">
                            <p className="widget-title">Store</p>
                            {/*<ul className="widget-links pl-0 list-unstyled ">*/}
                            {/*    <li><a href="/#">Catalog</a></li>*/}
                            {/*    <li><a href="/#">Popular</a></li>*/}
                            {/*    <li><a href="/#">Features</a></li>*/}
                            {/*    <li><a href="/#">F.a.q.</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer-widget widget3">
                            <p className="widget-title">About</p>
                            {/*<ul className="widget-links pl-0 list-unstyled ">*/}
                            {/*    <li><a href="/#">Catalog</a></li>*/}
                            {/*    <li><a href="/#">Popular</a></li>*/}
                            {/*    <li><a href="/#">Features</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer-widget widget4">
                            <p className="widget-title">Policy</p>
                            {/*<ul className="widget-links pl-0 list-unstyled ">*/}
                            {/*    <li><a href="/#">Catalog</a></li>*/}
                            {/*    <li><a href="/#">Popular</a></li>*/}
                            {/*    <li><a href="/#">Features</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer-widget widget4">
                            <p className="widget-title">Team</p>
                            {/*<ul className="widget-links pl-0 list-unstyled ">*/}
                            {/*    <li><a href="/#">Catalog</a></li>*/}
                            {/*    <li><a href="/#">Popular</a></li>*/}
                            {/*    <li><a href="/#">Features</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                    <div className="col">
                        <div className="footer-widget widget4">
                            <p className="widget-title">Support</p>
                            {/*<ul className="widget-links pl-0 list-unstyled ">*/}
                            {/*    <li><a href="/#">Catalog</a></li>*/}
                            {/*    <li><a href="/#">Popular</a></li>*/}
                            {/*    <li><a href="/#">Features</a></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*footer-bottom start */}
            <div className="copyright-area-l-17 text-center text-md-start">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4">
                            <div className="copyright">
                                <p> &copy; Grayic 2020 All right reserved. </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="footer-menu">
                                <ul className="list-unstyled d-flex flex-wrap justify-content-center">
                                    <li><a href="/#">Privacy Policy</a></li>
                                    <li><a href="/#">Terms & Conditions</a></li>
                                    <li><a href="/#"> Site map</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-2">
                            <div className="social-icons text-md-end">
                                <ul className="pl-0 list-unstyled">
                                    <li className="d-inline-block"><a href="/#"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li className="d-inline-block"><a href="/#"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li className="d-inline-block"><a href="/#"><i
                                        className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>

}