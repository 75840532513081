import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'antd/dist/reset.css';
import {LandingPage} from "./pages/landing";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<div>Coming Soon. Pls go to /landing to see landing</div>}/>
            <Route path="/landing" element={<LandingPage/>}/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
